<!-- 活动页 -->
<template>
  <div>
    <div class="footer">
         <div class="footer">
            <div class="row">
			  <div class="item">
			     <p class="label">Copyright@2024</p>
			  </div>
			  <div class="item">
			     <p class="label">沈阳哇塞科技有限公司版权所有</p>
			  </div>
              <div class="item">
                <a class="label" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2024028766号-1</a>
              </div>
              <span class="v-line"></span>
              <div class="item">
                <p class="label">辽ICP证20240584号</p>
              </div>
			  <div class="item">
			    <p class="label">辽网文(2024)2648-408号</p>
			  </div>
			  <div  class="item">
			    <p class="label"><a class="label" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽公网安备21010302666722号</a></p>
			  </div>
			  <div  class="item">
			    <p class="label" ><a class="label" target="_blank" href="https://jzicp.com/protocol/user-services.html">用户协议</a></p>
			  </div>
			  <span class="v-line"></span>
			  <div  class="item">
			    <p class="label"><a class="label" target="_blank" href="https://jzicp.com/protocol/privacy.html">隐私协议</a></p>
			  </div>
            </div>
          </div>
        </div>
</div>
		
</template>
<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.mg-footer {
  height: 80px;
  background: #272f3c;
}
.mgBox {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.footer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242A37;
  .row {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .v-line {
      display: inline-block;
      width: 1px;
      background-color: #CCCCCC;
      height: 12px;
    }
    .item {
      display: flex;
      align-items: center;
      .icon-address {
        width: 14px;
        height: 16px;
        margin-right: 20px;
      }
      .icon-email {
        width: 16px;
        height: 12px;
        margin-right: 20px;
      }
      
      .label {
          margin: 0;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #999999;
          line-height: 45px;
        }
    }
  }
  
}
</style>
