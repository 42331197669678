import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
  //return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJjaWNfdXNlcjoxODAzMjM3MjE0MTU4NjYzNjgxIiwicm5TdHIiOiJDbk9mY2tPU1Bla3hGMjg3MjRQY0VWZUhTNE5YeG1pSyIsInVzZXJJZCI6MTgwMzIzNzIxNDE1ODY2MzY4MX0.NBoYH9mLjsvpCAQAA8tKZktqfpBEWi5sYqCr1EP2MhU'
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getStorageKey(key) {
	let data;
	try{
		data = JSON.parse(localStorage.getItem(key))
	}catch(e){
		localStorage.getItem(key)
	}
  return data
}

export function setStorageKey(key,value) {
  return localStorage.setItem(key, JSON.stringify(value))
}

export function removeStorageKey(key) {
  return localStorage.removeItem(key)
}

export function clearStorageKey() {
  return localStorage.clear()
}